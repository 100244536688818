<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>Promotion Invitation Management</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="fetchData">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClearSearch"
        ></v-text-field>
      </v-form>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <v-data-table :headers="headers" :items="items" class="elevation-1" item-key="id" :loading="loading">
        <template v-slot:item.status="{ item }">
          <v-chip :color="statusColor(item.status)" class="ma-2" text-color="white">{{ item.status }}</v-chip>
        </template>

        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDateFromNow }}
        </template>

        <template v-slot:item.approve="{ item }">
          <v-btn
            v-if="item.status == 'PROCESSING'"
            class="ma-2"
            text
            icon
            color="blue lighten-2"
            @click="onApprove(item)"
          >
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.reject="{ item }">
          <v-btn
            v-if="item.status == 'PROCESSING'"
            class="ma-2"
            text
            icon
            color="red lighten-2"
            @click="onReject(item)"
          >
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-form v-model="valid">
          <v-card>
            <v-card-title v-if="isApproving" class="headline">Approve Invitation?</v-card-title>
            <v-card-title v-if="isRejecting" class="headline">Reject Invitation?</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p v-if="isApproving">
                    The Invitation request for <b> {{ selectedItem.requesterParticipant.fullName }} </b> for Participant
                    <b> {{ selectedItem.participant.fullName }}</b> will be Approved. An email will be sent.
                  </p>
                  <p v-if="isRejecting">
                    The Invitation request for <b> {{ selectedItem.requesterParticipant.fullName }} </b> for Participant
                    <b> {{ selectedItem.participant.fullName }}</b> will be Rejected.
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="isRejecting">
                <v-col cols="12">
                  <v-textarea
                    label="Comments"
                    name="reason"
                    id="reason"
                    v-model="rejectionReason"
                    :rules="rules.reason"
                    :error-messages="$error.getValidationError(errors, 'reason')"
                    @input="$error.clearValidationError(errors, 'reason')"
                  ></v-textarea> </v-col
              ></v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="onSubmit()" :loading="loadingDialog">{{
                $i18n.translate("Agree")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError },
  name: "ManagePromotionInvitations",
  metaInfo: {
    title: "Promotion Invitation Management"
  },
  data: function() {
    return {
      valid: false,
      loading: false,
      loadingDialog: false,
      dialog: false,
      isApproving: false,
      isRejecting: false,
      rejectionReason: undefined,
      selectedItem: {},
      search: "",
      headers: [
        { text: "Promotion", value: "promotion.promotionKey" },
        { text: "Participant", value: "participant.user.fullName" },
        { text: "Participant Email", value: "participant.email1" },
        {
          value: "participant.organization.name",
          text: "Company"
        },
        {
          value: "participant.participantType.name",
          text: "Participant Type",
          align: "end"
        },
        { value: "promotion.promotionInvitationApprovalsNeeded", text: "Approvals Needed" },
        {
          value: "createdDate",
          text: "Date Submitted",
          sortable: true
        },
        { text: "Approve", value: "approve", sortable: false },
        { text: "Reject", value: "reject", sortable: false }
      ],
      rules: {
        reason: [v => !!v || "An explanation is required"]
      },
      items: [],
      errors: {},
      options: {
        itemsPerPage: 10
      },
      total: 0
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    onClearSearch() {
      this.search = "";
      this.fetchData();
    },
    fetchData() {
      this.loading = true;

      let postForm = {};
      postForm.participantApprover = { id: this.selectedParticipant.id };

      if (this.search && this.search.length > 0) {
        postForm.keyword = this.search;
      }

      this.$api.post("/api/promotionInvitations/search", postForm).then(({ data }) => {
        this.items = data.content;
        this.loading = false;
      });
    },

    onClose() {
      this.dialog = false;
      this.isApproving = false;
      this.isRejecting = false;
    },

    onApprove(item) {
      this.selectedItem = item;
      this.dialog = true;
      this.isApproving = true;
      this.isRejecting = false;
    },
    onReject(item) {
      this.selectedItem = item;
      this.dialog = true;
      this.isApproving = false;
      this.isRejecting = true;
    },

    statusColor(status) {
      switch (status) {
        case "REJECTED":
          return "red";
        case "APPROVED":
          return "green";
        case "PROCESSING":
          return "primary";
      }
    },
    onSubmit() {
      this.loadingDialog = true;

      let serviceCall = null;
      if (this.isApproving) {
        serviceCall = this.$api.post("/api/promotionInvitations/" + this.selectedItem.id + "/approve", {});
      } else if (this.isRejecting) {
        let rejectForm = {};
        rejectForm.participantRejection = { id: this.selectedParticipant.id };
        rejectForm.reason = this.rejectionReason;
        rejectForm.rejectionEmailTemplateKey = "PROMOTION_INVITATION_REJECTED";

        serviceCall = this.$api.post("/api/promotionInvitations/" + this.selectedItem.id + "/reject", rejectForm);
      }
      serviceCall
        .then(() => {
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.onClose();
        });
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
